<template>
  <div class="scale-card-container">
    <div v-for="item in scale" :class="current === item.id ? 'scale-card scale-card-selected' : 'scale-card'">
      <div :style="{width: item.w + 'px', height: item.h + 'px'}"
           class="card-inner"></div>
      <span>
        {{ item.text }}
      </span>
    </div>
  </div>
</template>
<script>
export default ({
  data() {
    return {
      scale: [
        {id: 1, w: 32, h: 32, text: '1:1'},
        {id: 2, w: 24, h: 32, text: '3:4'},
        {id: 3, w: 32, h: 24, text: '4:3'},
        {id: 4, w: 18, h: 32, text: '9:16'},
        {id: 5, w: 32, h: 18, text: '16:9'}],
      current: 1
    }
  }
})
</script>

<style scoped lang="scss">
.scale-card-container {
  display: flex;
  justify-content: space-around;

  .scale-card {
    border: 1px solid #e3e4e5;
    height: 65px;
    width: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    span {
      color: #e3e4e5;
    }

    .card-inner {
      background: #f0f2f5;
    }
  }

  .scale-card:hover, .scale-card-selected {
    cursor: pointer;
    border: 1px solid rgba(83, 105, 118, 0.43);

    .card-inner {
      background: rgba(0, 92, 253, 0.3);
    }

    span {
      color: rgba(0, 92, 253, 0.5);
    }
  }
}
</style>